export function formatDurationTime(totalSeconds, hideZeroHours = true) {
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    // const seconds = totalSeconds % 60;

    // Format the result based on the hideZeroHours flag
    let result = '';
    if (hours > 0 || !hideZeroHours) {
        result += `${hours} hr`;
    }
    if (minutes > 0) {
        result += (result ? ', ' : '') + `${minutes} mins`;
    }
    // if (seconds > 0 || (!hours && !minutes)) {
    //     result += (result ? ', ' : '') + `${seconds} second(s)`;
    // }

    return result;
}

// Example usage
// console.log(formatDurationTime(5543 + 2)); // "1 hour(s), 32 minute(s), and 5 second(s)"
// console.log(formatDurationTime(120, true)); // "2 minute(s)"
// console.log(formatDurationTime(45, false)); // "0 hour(s), 0 minute(s), and 45 second(s)"
