import React, { useState, useEffect } from 'react';
import { GoogleMap, DirectionsService, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { MAP_KEY } from '../../config';
import GeocodeAddress from '../GeoCodeAddress';
import { useNavigate } from 'react-router-dom';

// Haversine formula to calculate distance between two lat/lng points
const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
};

const addMinutes = (duration, minutesToAdd) => {
    // Convert minutes to seconds and add to the duration value
    const updatedValue = duration + minutesToAdd * 60;

    return updatedValue;
};

const MapWithRoute = () => {
    const navigate = useNavigate()
    const [currentLocation, setCurrentLocation] = useState(null);
    const [targetLocation, setTargetLocation] = useState(null);
    const [waypoints, setWaypoints] = useState([]); // Array to store stops
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [arrivalTime, setArrivalTime] = useState(null);
    const [startTime, setStartTime] = useState(null); // Track start time
    const [timeDifference, setTimeDifference] = useState({}); // Object to hold days, hours, minutes
    const [routeSegments, setRouteSegments] = useState([]); // Array to hold time for each segment
    const [showRoute, setShowRoute] = useState(false); // Track if route should be displayed

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: MAP_KEY,
    });

    useEffect(() => {
        if (currentLocation && targetLocation && showRoute) {
            setDirectionsResponse(null); // Reset response to ensure fresh API call
            setArrivalTime(null);
            setStartTime(null);
            setTimeDifference({});
            setRouteSegments([]);
        }
    }, [currentLocation, targetLocation, waypoints, showRoute]);

    const handleDirectionsCallback = (response) => {
        if (response && response.status === 'OK') {
            setDirectionsResponse(response);

            const now = new Date();
            setStartTime(now);

            // Calculate the total duration for the entire route
            const totalDurationInSeconds = response.routes[0].legs.reduce((total, leg) => total + leg.duration.value, 0);
            const arrival = new Date(now.getTime() + totalDurationInSeconds * 1000);
            setArrivalTime(arrival);

            // Calculate the time difference in days, hours, and minutes
            const diffInMs = arrival - now;
            const minutes = Math.floor((diffInMs / 1000 / 60) % 60);
            const hours = Math.floor((diffInMs / 1000 / 60 / 60) % 24);
            const days = Math.floor(diffInMs / 1000 / 60 / 60 / 24);
            setTimeDifference({ days, hours, minutes });

            // Calculate ETA for each segment based on cumulative duration
            let cumulativeDuration = 0;
            const segments = response.routes[0].legs.map((leg, index) => {

                // Add the leg's duration in seconds to the cumulative duration
                cumulativeDuration += addMinutes(leg.duration.value, 2);
                const eta = new Date(now.getTime() + cumulativeDuration * 1000); // Calculate ETA for this segment

                return {
                    segment: `Segment ${String.fromCharCode(65 + index)}`, // A, B, C...
                    startAddress: leg.start_address,
                    endAddress: leg.end_address,
                    duration: leg.duration.text,
                    eta: eta.toLocaleTimeString(), // Format ETA as a time string
                };
            });

            setRouteSegments(segments);
        } else if (response && response.status === 'OVER_QUERY_LIMIT') {
            console.warn('Rate limit exceeded. Consider adding a delay or limiting API calls.');
        } else {
            console.error('Error fetching directions', response);
        }
    };


    const addStop = (locationData) => {
        const latLng = { lat: locationData?.location?.lat, lng: locationData?.location?.lng }; // Extract lat and lng
        setWaypoints(prevWaypoints => [...prevWaypoints, { location: latLng, stopover: true }]);
    };

    const sortWaypoints = (origin, waypoints) => {
        // Sort waypoints based on their proximity to the origin using Haversine distance
        const sortedWaypoints = [...waypoints].sort((a, b) => {
            const distanceA = haversineDistance(origin.lat, origin.lng, a.location.lat, a.location.lng);
            const distanceB = haversineDistance(origin.lat, origin.lng, b.location.lat, b.location.lng);
            return distanceA - distanceB;
        });
        return sortedWaypoints;
    };

    const handleGetRoute = () => {
        if (currentLocation) {
            const sortedWaypoints = sortWaypoints(currentLocation, waypoints);
            setWaypoints(sortedWaypoints); // Update waypoints to be sorted
            setShowRoute(true);
        } else {
            alert("please select source and destination")
        }
    };

    if (!isLoaded) return <div>Loading...</div>

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-lg-3 col-sm-6" style={{ height: '95vh', overflow: 'auto' }}>
                    <div className="d-flex gap-2">
                        <button className='btn btn-sm btn-outline-info' type="button" onClick={() => {
                            navigate('/addAddress')
                        }}>Save Address</button>
                        <button className='btn btn-sm btn-outline-info' type="button" onClick={() => {
                            navigate('/listAddress')
                        }}>List Address</button>
                        <button className='btn btn-sm btn-outline-info' type="button" onClick={() => {
                            navigate('/createRoutes')
                        }}>Create Routes</button>
                    </div>
                    <GeocodeAddress
                        setSourceLocation={(data) => setCurrentLocation(data)}
                        setDestinationLocation={(data) => setTargetLocation(data)}
                        addStopLocation={(data) => addStop(data)}
                    />
                    <button onClick={handleGetRoute} className="btn btn-primary mt-3 w-100">Get Route</button>
                </div>
                <div className="col-lg-3 col-sm-12" style={{ height: '95vh', overflow: 'auto' }}>
                    {arrivalTime && startTime && (
                        <div className="mt-3">
                            <h4>Route Information</h4>
                            <p><strong>Start Time:</strong> {startTime.toLocaleTimeString()}</p>
                            <p><strong>Estimated Arrival Time:</strong> {arrivalTime.toLocaleTimeString()}</p>
                            <p><strong>Total Time:</strong> {timeDifference.days} days, {timeDifference.hours} hours, {timeDifference.minutes} minutes</p>

                            <h5>Segment Durations</h5>
                            <div className='m-1'>
                                <ul className="list-group">
                                    {routeSegments.map((segment, index) => {

                                        return (
                                            <li key={index} className="list-group-item">
                                                <strong>{segment.segment}</strong><br />
                                                <strong>From:</strong> {segment.startAddress} <br />
                                                <strong>To:</strong> {segment.endAddress} <br />
                                                <strong>Duration:</strong> {segment.duration} <br />
                                                <strong>ETA:</strong> {segment.eta}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-lg-6 col-sm-12">
                    {currentLocation && targetLocation && showRoute && (
                        <GoogleMap
                            center={currentLocation}
                            zoom={10}
                            mapContainerStyle={{ width: '100%', height: '650px' }}
                        >
                            {currentLocation.lat !== 0 && targetLocation && !directionsResponse && (
                                <DirectionsService
                                    options={{
                                        origin: currentLocation,
                                        destination: targetLocation,
                                        travelMode: 'DRIVING',
                                        waypoints: waypoints,
                                    }}
                                    callback={handleDirectionsCallback}
                                />
                            )}
                            {directionsResponse && (
                                <DirectionsRenderer
                                    options={{
                                        directions: directionsResponse,
                                    }}
                                />
                            )}
                        </GoogleMap>
                    )}
                </div>

            </div>
        </div>
    );
};

export default MapWithRoute;
