import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import './App.css';
import Calculator from "./Components/MapWithRoute";
import AddAddress from "./Components/AddAddress";
import AddressesList from "./Components/ShowAddress";
import { CreateRoutes } from "./Components/MultiAddress/createRoutes";
import RouteMap from "./Components/RouteMap";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Calculator />} />
          <Route path="/addAddress" element={<AddAddress />} />
          <Route path="/listAddress" element={<AddressesList />} />
          <Route path="/createRoutes" element={<CreateRoutes />} />
          <Route path="/routeMap" element={<RouteMap />} />
        </Routes>
      </BrowserRouter>
      <div className="mt-1 mb-1" style={{ textAlign: 'center' }}>
        Novisync © Copyright 2024. All Rights Reserved. Release Date: 11/13/2024 2:40 AM IST
      </div>
    </div>
  );
}

export default App;
