import React, { useState, useRef, useEffect } from 'react';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { MAP_KEY } from '../../config';

const libraries = ['places']; // Specify 'places' library

const PlaceAutocomplete = ({ label, setLocation }) => {
    const searchBoxRef = useRef(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: MAP_KEY,
        libraries,
    });

    const onLoad = (ref) => {
        searchBoxRef.current = ref;
    };

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places.length > 0) {
            const selectedPlace = places[0];
            setLocation({
                name: selectedPlace.name,
                address: selectedPlace?.formatted_address,
                location: {
                    lat: selectedPlace?.geometry?.location.lat(),
                    lng: selectedPlace?.geometry?.location.lng(),
                },
            });
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div className="mt-2">
            <label>{label}</label>
            <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                <input
                    type="text"
                    placeholder={`Enter ${label.toLowerCase()}`}
                    style={{ width: '100%', padding: '10px', fontSize: '16px' }}
                />
            </StandaloneSearchBox>
        </div>
    );
};

const GeocodeAddress = ({ setSourceLocation, setDestinationLocation, addStopLocation }) => {
    const [source, setSource] = useState(null);
    const [destination, setDestination] = useState(null);
    const [stops, setStops] = useState([]); // Array to hold multiple stops

    useEffect(() => {
        // Only update if source or destination changes
        if (source) {
            setSourceLocation({
                lat: source.location.lat,
                lng: source.location.lng,
            });
        }
        if (destination) {
            setDestinationLocation({
                lat: destination.location.lat,
                lng: destination.location.lng,
            });
        }
        // eslint-disable-next-line
    }, [source, destination]); // Only run effect when source or destination changes

    const handleRemoveStop = (index) => {
        const updatedStops = stops.filter((_, stopIndex) => stopIndex !== index);
        setStops(updatedStops);
        addStopLocation(updatedStops)
    };

    const addNewStopInput = () => {
        setStops([...stops, null]); // Add a placeholder for a new stop
    };

    const updateStopLocation = (index, location) => {
        const updatedStops = [...stops];
        updatedStops[index] = location;
        setStops(updatedStops);
        addStopLocation(location);
    };

    return (
        <div>
            <h2>Select Source & Destination</h2>
            <PlaceAutocomplete label="Source Address" setLocation={setSource} />
            <PlaceAutocomplete label="Destination Address" setLocation={setDestination} />

            {source && (
                <div>
                    <h3>Source</h3>
                    {/* <p>Name: {source.name}</p> */}
                    <p>Address: {source.address}</p>
                    {/* <p>Latitude: {source.location.lat}</p>
                    <p>Longitude: {source.location.lng}</p> */}
                </div>
            )}

            {destination && (
                <div>
                    <h3>Destination</h3>
                    {/* <p>Name: {destination.name}</p> */}
                    <p>Address: {destination.address}</p>
                    {/* <p>Latitude: {destination.location.lat}</p>
                    <p>Longitude: {destination.location.lng}</p> */}
                </div>
            )}

            {
                source && destination && (
                    <div className="mt-4">
                        <h3>Stop Locations</h3>
                        {stops.map((stop, index) => (
                            <div key={index} style={{ marginBottom: '10px' }}>
                                <PlaceAutocomplete
                                    label={`Stop ${index + 1}`}
                                    setLocation={(location) => updateStopLocation(index, location)}
                                />
                                {stop && (
                                    <div>
                                        {/* <p>Name: {stop.name}</p> */}
                                        <p>Address: {stop.address}</p>
                                        {/* <p>Latitude: {stop.location.lat}</p>
                                        <p>Longitude: {stop.location.lng}</p> */}
                                    </div>
                                )}
                                <button
                                    onClick={() => handleRemoveStop(index)}
                                    style={{
                                        padding: '5px',
                                        color: 'white',
                                        backgroundColor: 'red',
                                        border: 'none',
                                        borderRadius: '5px',
                                        marginTop: '5px',
                                    }}
                                >
                                    Remove Stop
                                </button>
                            </div>
                        ))}
                        <button
                            onClick={addNewStopInput}
                            style={{
                                padding: '10px',
                                color: 'white',
                                backgroundColor: 'green',
                                border: 'none',
                                borderRadius: '5px',
                                marginTop: '10px',
                            }}
                        >
                            Add Stop
                        </button>
                    </div>
                )
            }

        </div>
    );
};

export default GeocodeAddress;
