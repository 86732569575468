export function groupAddressesByRadius(addresses, radiusInKm) {
    const EARTH_RADIUS_KM = 6371;

    // Haversine formula to calculate distance between two coordinates
    function haversineDistance(lat1, lng1, lat2, lng2) {
        const toRadians = (deg) => (deg * Math.PI) / 180;
        const dLat = toRadians(lat2 - lat1);
        const dLng = toRadians(lng2 - lng1);

        const a =
            Math.sin(dLat / 2) ** 2 +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLng / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return EARTH_RADIUS_KM * c;
    }

    const groups = [];
    const visited = new Set();

    // Preprocess to parse lat/lng as floats for all addresses
    const parsedAddresses = addresses
    .sort((a, b) => {
        const latDiff = parseFloat(a.lat) - parseFloat(b.lat);
        if (latDiff !== 0) {
            return latDiff;
        }
        return parseFloat(a.lng) - parseFloat(b.lng);
    })
    .map((address) => ({
        ...address,
        lat: parseFloat(address.lat),
        lng: parseFloat(address.lng),
    }));

    parsedAddresses.forEach((address, index) => {
        if (!visited.has(index)) {
            const group = [address];
            visited.add(index);

            parsedAddresses.forEach((otherAddress, otherIndex) => {
                if (index !== otherIndex && !visited.has(otherIndex)) {
                    const distance = haversineDistance(
                        address.lat,
                        address.lng,
                        otherAddress.lat,
                        otherAddress.lng
                    );

                    if (distance <= radiusInKm) {
                        group.push(otherAddress);
                        visited.add(otherIndex);
                    }
                }
            });

            groups.push(group);
        }
    });

    return groups;
}

// Usage example
// const addresses = [ /* the addresses array */ ];
// const radius = 2; // 2 km radius
// const groupedAddresses = groupAddressesByRadius(addresses, radius);
// console.log(groupedAddresses);
