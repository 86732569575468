import React, { useState, useRef, useEffect } from 'react';
import { useLoadScript, StandaloneSearchBox, GoogleMap, Marker } from '@react-google-maps/api';
import { MAP_KEY } from '../../config';
import { useNavigate } from 'react-router-dom';
import api from '../../Common/Network/axiosConfig';

const libraries = ['places'];

const PlaceAutocomplete = ({ label, setLocation }) => {
    const searchBoxRef = useRef(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: MAP_KEY,
        libraries,
    });

    const onLoad = (ref) => {
        searchBoxRef.current = ref;
    };

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places.length > 0) {
            const selectedPlace = places[0];
            setLocation({
                name: selectedPlace.name,
                address: selectedPlace?.formatted_address,
                location: {
                    lat: selectedPlace?.geometry?.location.lat(),
                    lng: selectedPlace?.geometry?.location.lng(),
                },
            });
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div className="mt-2">
            <label>{label}</label>
            <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                <input
                    type="text"
                    placeholder={`Enter ${label.toLowerCase()}`}
                    style={{ width: '100%', padding: '10px', fontSize: '16px' }}
                />
            </StandaloneSearchBox>
        </div>
    );
};

const MapSelector = ({ setLocation, onClose }) => {
    const [markerPosition, setMarkerPosition] = useState(null);
    const [address, setAddress] = useState('');

    useEffect(() => {
        // Get the user's current location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setMarkerPosition({ lat: latitude, lng: longitude });
                    reverseGeocode({ lat: latitude, lng: longitude });
                },
                (error) => console.error("Error getting location:", error),
                { enableHighAccuracy: true }
            );
        }
        // eslint-disable-next-line
    }, []);

    const reverseGeocode = (position) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: position }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setAddress(results[0].formatted_address);
                setLocation({
                    name: results[0].address_components[0]?.long_name || 'Selected Location',
                    address: results[0].formatted_address,
                    location: position,
                });
            } else {
                console.error('Geocode error:', status);
            }
        });
    };

    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const position = { lat, lng };
        setMarkerPosition(position);
        reverseGeocode(position);
    };

    return (
        <div className="map-overlay">
            <GoogleMap
                center={markerPosition}
                zoom={14}
                mapContainerStyle={{ width: '100%', height: '78vh' }}
                onClick={handleMapClick}
            >
                {markerPosition && (
                    <Marker
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={(e) => {
                            const lat = e.latLng.lat();
                            const lng = e.latLng.lng();
                            const position = { lat, lng };
                            setMarkerPosition(position);
                            reverseGeocode(position);
                        }}
                    />
                )}
            </GoogleMap>
            <div className="mt-3">
                <p>Address: {address || 'Click on the map to select a location'}</p>
                <div className="d-flex gap-2 col-6 mt-2">

                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Set Location
                    </button>
                    <button className="btn btn-secondary" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const AddAddress = () => {
    const navigate = useNavigate()
    const [address, setAddress] = useState(null);
    const [isMapOpen, setIsMapOpen] = useState(false);

    const resetScreen = () => {
        setAddress(null)
    }

    const saveAddress = async (type) => {
        if (address === null) {
            alert('please select address');
        } else {
            try {
                const response = await api.post('/address/saveAddress', {
                    address_name: address.name,
                    full_address: address.address,
                    lat: address.location.lat,
                    lng: address.location.lng,
                    address_type: type
                });
                if (response) {
                    console.log('Address added successfully');
                    setAddress(null)
                }
            } catch (error) {
                console.error('Error saving address', error);
            }
        }
    };



    return (
        <div className="m-2">
            <div className='row'>

                <div className="col-md-3">
                    <div className="d-flex gap-2">
                        <button className='btn btn-sm btn-outline-info' type="button" onClick={() => {
                            navigate('/')
                        }}>Home</button>
                        <button className='btn btn-sm btn-outline-info' type="button" onClick={() => {
                            navigate('/listAddress')
                        }}>View Address</button>
                        <button className='btn btn-sm btn-outline-info' type="button" onClick={() => {
                            navigate('/createRoutes')
                        }}>Create Routes</button>
                    </div>
                    <h2>Select Location</h2>
                    <PlaceAutocomplete label="Search" setLocation={setAddress} />
                    {address ? (
                        <div>
                            <h3>Source</h3>
                            <p>Name: {address.name}</p>
                            <p>Address: {address.address}</p>
                            <p>Latitude: {address.location.lat}</p>
                            <p>Longitude: {address.location.lng}</p>
                        </div>
                    ) :
                        null}
                    <button
                        className="btn btn-primary mt-2"
                        onClick={() => setIsMapOpen(true)}
                    >
                        Set Location on Map
                    </button>
                    <div className="d-flex gap-2 col-6 mt-2">
                        <button className="btn btn-success" onClick={() => saveAddress('destination')}>Save</button>
                        <button className="btn btn-danger" onClick={() => {
                            resetScreen()
                        }} >Cancel</button>
                    </div>
                    <div className="d-flex gap-2 col-6 mt-2">
                        <button className="btn btn-success" onClick={() => saveAddress('source')}>Save Source</button>
                    </div>
                </div>
                <div className="col-md-9">
                    {isMapOpen && (
                        <MapSelector
                            setLocation={setAddress}
                            onClose={() => setIsMapOpen(false)}
                        />
                    )}
                </div>
            </div>
        </div >
    );
};

export default AddAddress;
