import axios from 'axios';
import { DELIVERY_PARTNER_SERVER_URL } from '../../config';

// Create an Axios instance
const api = axios.create({
    baseURL: DELIVERY_PARTNER_SERVER_URL,
    headers: {
        'Content-Type': 'application/json',      // Setting default Content-Type
        'Accept': 'application/json',            // Setting default Accept header
        'X-Custom-Header': 'CustomHeaderValue',  // Example custom header
    },
});

// Request Interceptor
api.interceptors.request.use(
    (config) => {
        // Optionally add authorization token to headers
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        // If no token, continue without setting the Authorization header
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response Interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                console.error("Unauthorized - Redirecting to login");
            } else if (error.response.status === 500) {
                console.error("Server Error - Please try again later");
            }
        }
        return Promise.reject(error);
    }
);

export default api;
